import React from "react"
import { message, Radio, Modal, Button } from "antd"
import { navigate } from "gatsby"
import QRCode from "qrcode.react"
import { getOrderInfo } from "../api/order"
import { generatePreOrder, getItemByOrderId } from "../api/item"
import Layout from "../layouts"
import { queryParse, isPC } from "../utils/helper"
import { RANDOM_CODE_COUNTDOWN } from "../utils/constant"

import "./items.css"

// 统一付款页
class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item: {
        name: "...",
        description: "...",
        outOrderId: "...",
        price: "...",
        isExpired: false,
      },
    }
    this.checkLoginCountdown = RANDOM_CODE_COUNTDOWN
    this.timer = null
  }

  componentWillMount() {
    this.init()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  init = async () => {
    await this.initItemDetail()
    if (isPC()) {
      await this.startCheckOrderCountdown()
    }

    // 微信移动端支付JSAPI模式
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (typeof window.WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          )
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady)
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady)
        }
      }
    }
  }

  onBridgeReady = () => {
    const {
      appId,
      timeStamp,
      nonceStr,
      paySign,
      signType,
      prepayId,
    } = this.state.item
    if (typeof window !== "undefined") {
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId, //公众号名称，由商户传入
          timeStamp, //时间戳，自1970年以来的秒数
          nonceStr, //随机串
          package: `prepay_id=${prepayId}`,
          signType, //微信签名方式：
          paySign, //微信签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            const param = queryParse()
            if (param.type === "report") {
              Modal.info({
                title: "支付成功！",
                content: "该商品已经购买完成，点击确认进入报告配置页进行配置",
                onOk: () => {
                  navigate("/reports/config")
                },
              })
            } else if (param.type === "theme") {
              Modal.info({
                title: "支付成功！",
                content: "该商品已经购买完成，点击确认关闭购买页，查看更多全部职位",
                onOk: () => {
                  navigate("/themes")
                },
              })
            } else if (param.type === "activeCode") {
              Modal.info({
                title: "支付成功！",
                content: "该商品已经购买完成，点击确认按钮或查看手机获取激活码",
                onOk: () => {
                  navigate("/membership/active-code")
                },
              })
            } else {
              Modal.info({
                title: "支付成功！",
                content: "该商品已经购买完成，点击确认关闭购买页",
                onOk: () => {
                  navigate("/orders")
                },
              })
            }
            return
          }
        }
      )
    }
  }

  initItemDetail = async () => {
    const param = queryParse()
    if (param.id === undefined && param.orderId === undefined) {
      message.error("加载商品界面出错")
      return
    }
    const tradeType = isPC() ? "NATIVE" : "JSAPI"
    let res
    if (param.id) {
      res = await generatePreOrder(param.id, tradeType)
    } else if (param.orderId) {
      res = await getItemByOrderId(param.orderId, tradeType)
    }
    if (res === undefined) {
      return
    }
    const data = res.data.data
    if (data) {
      this.setState({ item: data })
      const url = `/items?orderId=${data.outOrderId}&type=${param.type}`
      if (typeof window !== "undefined") {
        window.history.pushState({ url: url }, "", url)
      }
    } else {
      message.error("加载商品界面出错")
    }
  }

  startCheckOrderCountdown = async () => {
    const param = queryParse()
    if (this.state.item.outOrderId === "...") {
      return
    }
    this.setState({ isExpired: false })
    if (this.checkLoginCountdown === 0) {
      this.checkLoginCountdown = RANDOM_CODE_COUNTDOWN
      this.setState({ isExpired: true })
      clearTimeout(this.timer)
    } else {
      this.checkLoginCountdown--
      const res = await getOrderInfo(this.state.item.outOrderId)
      const data = res.data.data

      // 注册态
      if (data.status === 2) {
        // 支付成功
        clearTimeout(this.timer)
        const param = queryParse()
        if (param.type === "report") {
          Modal.info({
            title: "支付成功！",
            content: "该商品已经购买完成，点击确认进入报告配置页进行配置",
            onOk: () => {
              navigate("/reports/config")
            },
          })
        } else if (param.type === "theme") {
          Modal.info({
            title: "支付成功！",
            content: "该商品已经购买完成，点击确认关闭购买页，查看更多全部职位",
            onOk: () => {
              navigate("/themes")
            },
          })
        } else if (param.type === "activeCode") {
          Modal.info({
            title: "支付成功！",
            content: "该商品已经购买完成，点击确认按钮或查看手机获取激活码",
            onOk: () => {
              navigate("/membership/active-code")
            },
          })
        } else {
          Modal.info({
            title: "支付成功！",
            content: "该商品已经购买完成，点击确认关闭购买页",
            onOk: () => {
              navigate("/orders")
            },
          })
        }
        return
      }
      this.timer = setTimeout(() => {
        this.startCheckOrderCountdown()
      }, 1000)
    }
  }

  handlePayWayChange = () => {}

  render() {
    const options = [{ label: "微信支付", value: "微信支付" }]
    const { item, isExpired } = this.state
    return (
      <Layout isSpacerDisplay={true} title="商品购买">
        <div className="mdnice-item-container">
          <div className="mdnice-item-inner-container">
            <div className="mdnice-item-row">
              <strong>商品名称：</strong>
              <span>{item.name}</span>
            </div>
            <div className="mdnice-item-row">
              <strong>商品描述：</strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></div>
            </div>
            <div className="mdnice-item-row">
              <strong>商品订单：</strong>
              <span>{item.outOrderId}</span>
            </div>
            <div className="mdnice-item-row">
              <strong>支付金额：</strong>
              <span className="mdnice-item-price">
                {item.price === "..." ? "..." : item.price / 100.0}
              </span>
              <span> 元</span>
            </div>
            <div className="mdnice-item-row">
              <span>
                注：虚拟商品，购买后无法退款。长时间无法完成支付将自动关闭订单
              </span>
            </div>
            <hr />

            {isPC() ? (
              <>
                <strong>支付方式：</strong>
                <div className="mdnice-item-row">
                  <Radio.Group
                    options={options}
                    onChange={this.handlePayWayChange}
                    value="微信支付"
                    optionType="button"
                  />
                </div>
                <div className="mdnice-item-down">
                  <div className="mdnice-item-qrcode">
                    {isExpired ? (
                      <a onClick={this.startCheckOrderCountdown}>
                        <div className="expired">
                          <p>二维码已过期</p>
                          <p>点击重新获取</p>
                        </div>
                      </a>
                    ) : (
                      item.wxPayCodeURL && (
                        <QRCode size={220} value={item.wxPayCodeURL} />
                      )
                    )}
                    <span className="mdnice-item-tips">
                      请打开手机微信，扫一扫完成支付
                    </span>
                  </div>
                  <img
                    className="item-intro-img"
                    src="https://files.mdnice.com/pic/db93defc-4d86-4c8d-a260-29408ec8e8c2.png"
                  />
                </div>
              </>
            ) : (
              <div>
                <Button type="primary" onClick={this.onBridgeReady}>
                  立即支付
                </Button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Item
