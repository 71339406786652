import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const getOrderList = async () => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/orders/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getOrderInfo = async outId => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/orders/${outId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getUniqueOrder = async itemId => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/orders/items/${itemId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getActiveCodeOrderList = async () => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/activeCode/query`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
